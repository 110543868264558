/* eslint-disable camelcase */
import { FountainLocation } from '@fountain/types/base';
import { createSelector } from 'reselect';

import { ADMIN_ROLE } from './constants';
import { initialState } from './reducer';
/**
 * Direct selector to the auth state domain
 */
export type SourcingDescriptionSource =
  | 'custom_description'
  | 'position_description';

interface ReduxFountainLocation extends FountainLocation {
  isSelected?: boolean;
}
export interface Brand {
  name: string;
  slug: string;
  id: string;
  brand_id: number;
  logo_url: string;
  locations: ReduxFountainLocation[];
  isAccount: boolean;
  industry?: unknown;
  default?: boolean;
  ax_enabled: boolean;
  isSelected: boolean;
}
export interface WhoAmI {
  agent_number: string;
  email: string;
  name: string;
  role: string;
  menu: string[];
  brands: Brand[];
  phone_number?: string;
  is_faq_bot_enabled: boolean;
  is_impersonated: boolean;
  is_owner: boolean;
  is_super_admin: boolean;
  true_user_email: string;
  external_id: string;
  wotc_enabled: boolean;
  batch_csv_enabled: boolean;
  whats_app_enabled: boolean;
  time_based_duplicate_handling: boolean;
  chatbot_admin_enabled: boolean;
  auto_generate_dummy_email_enabled: boolean;
  chatbot_review_enabled: boolean;
  chatbot_automated_response_enabled: boolean;
  fountain_ai_enabled: boolean;
  fountain_ai_faq_enabled: boolean;
  has_sub_accounts: boolean;
  post_interview_recruiter_notifications_enabled: boolean;
  slot_availability_based_openings_enabled: boolean;
  use_applicant_table_v2_only: boolean;
  accessible_account_slugs: string[];
  account_id: string;
  account_name: string;
  account_name_v2: string;
  account_type: string;
  account_sms_enabled: boolean;
  account_has_legacy_workflows: boolean;
  account_has_workflows: boolean;
  bulk_locations_beta: boolean;
  perms: string[];
  current_funnel_slug: string;
  current_funnel_id: string;
  keywords_purchased: number;
  time_zone: string;
  user_type: string;
  country_code: string;
  client_settings: {
    user: unknown;
    quickActions: boolean;
    scheduleSessionColumnSettings?: {
      key: string;
      title: string;
      category: string;
      key_type: string;
    }[];
  };
  enforce_brand_based_permissions: boolean;
  email_for_messenger_enabled: boolean;
  opening_approval_enabled: boolean;
  policies: {
    manage_account: boolean;
    view_messaging_thread: boolean;
    view_jobs_directory: boolean;
    manage_funnels: boolean;
    view_funnels: boolean;
    manage_workflows: boolean;
    view_workflows: boolean;
    manage_opening_approvers: boolean;
  };
  posthire_enabled: boolean;
  analytics_enabled: boolean;
  looker_custom_reports_enabled: boolean;
  created_at: string;
  last_sign_in_at: string;
  recruiter_ui_env_vars: {
    REACT_APP_CHARGEBEE_ANNUAL_PLAN_ID: string;
    REACT_APP_CHARGEBEE_MONTHLY_PLAN_ID: string;
    REACT_APP_FACEBOOK_APP_ID: string;
    REACT_APP_HELLOSIGN_CLIENT_ID: string;
    REACT_APP_PUSHER_APP_KEY: string;
    REACT_APP_PUSHER_CLUSTER: string;
    REACT_APP_STRIPE_KEY: string;
    REACT_APP_ONESIGNAL_WEB_PUSH_APP_ID: string;
  };
  bulk_editing_enabled: boolean;
  unread_sms: boolean;
  get_more_text_to_apply_enabled: boolean;
  hiring_goals_enabled: boolean;
  hiring_goals_v3_enabled: boolean;
  locale?: string;
  desktop_notification_enabled: boolean;
  has_restricted_funnel_access: boolean;
  first_job_id?: unknown;
  instant_interview_enabled: boolean;
  immediate_hiring_decision_enabled: boolean;
  intercom_digest?: string;
  workflow_editor_v2_enabled: boolean;
  workflow_system_enabled: boolean;
  feature_flags: {
    [key: string]: boolean;
  };
  docusign_qes_feature_enabled: boolean;
  fountain_ai_carreer_site_setting_enabled: boolean;
  fountain_ai_career_site_enabled: boolean;
  selected_account_slug: string;
  default_brand_id: string;
  funnel_status_based_off_slots_enabled: boolean;
  qr_code_for_opening_enabled: boolean;
  can_create_standard_workflow_templates: boolean;
  calendar_event_creation_enabled: boolean;
  event_adaptive_enabled: boolean;
  can_send_only_templates_in_sms: boolean;
  wx_applicant_sync_webhooks_exist: boolean;
}
interface User {
  isSelected?: boolean;
}
interface SuperAdmin {
  accounts: unknown;
  users: User[];
}
interface Auth {
  error?: Error;
  whoami: WhoAmI;
  signedOut: boolean;
  isLoading: boolean;
  superAdmin: SuperAdmin;
  isUpdatingClientSettings: boolean;
  clientSettingsUpdateSuccess: boolean;
  isFetchingAllBrands: boolean;
  fetchingAllBrandsError?: Error;
  isAllBrandsFetched: boolean;
}

const selectAuthDomain = (state: { auth: Auth }) => state.auth || initialState;

const selectBrands = (state: { auth: Auth }) => state.auth.whoami.brands;

const makeSelectWhoami = () =>
  createSelector(selectAuthDomain, authState => authState.whoami);

const makeSelectSelectedAccountSlug = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.selected_account_slug);

const makeSelectAccessibleAccountSlugs = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.accessible_account_slugs,
  );

/**
 * Other specific selectors
 */
const makeSelectUserName = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.name);

const makeSelectPhoneNumber = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.phone_number);

const makeSelectEmail = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.email);

const makeSelectExternalId = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.external_id);

const makeSelectUnreadSms = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.unread_sms);

const makeSelectAccountName = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.account_name_v2);

const makeSelectIsUpdatingClientSettings = () =>
  createSelector(
    selectAuthDomain,
    authState => authState.isUpdatingClientSettings,
  );

const makeSelectClientSettings = () =>
  createSelector([makeSelectWhoami()], whoami => ({
    clientSettings: whoami.client_settings,
  }));

const makeSelectIsLoggedIn = () =>
  createSelector(
    [makeSelectWhoami(), makeSelectUserName(), makeSelectEmail()],
    (whoami, name, email) => !!whoami && !!name && !!email,
  );

const makeSelectWhoamiError = () =>
  createSelector(selectAuthDomain, authState => authState.error);

const makeSelectBrands = () => createSelector(selectBrands, brands => brands);

const makeIsAllBrandsFetched = () =>
  createSelector(selectAuthDomain, authState => authState.isAllBrandsFetched);

const makeIsFetchingAllBrands = () =>
  createSelector(selectAuthDomain, authState => authState.isFetchingAllBrands);

const makeSelectLocations = () =>
  createSelector([makeSelectBrands()], brands => {
    const selectedBrand = brands.find(brand => brand.isSelected);
    return selectedBrand ? selectedBrand.locations : [];
  });

const makeSelectCurrentLocation = () =>
  createSelector([makeSelectLocations()], locations => {
    if (locations && locations.length)
      return locations.find(loc => loc.isSelected);
    return {};
  });

const makeSelectAccountSmsEnabled = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.account_sms_enabled);

const makeSelectTelephonyEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => !!(whoami.feature_flags.telephony && whoami.agent_number),
  );

const makeSelectAccountHasWorkflows = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.account_has_workflows);

const makeSelectAccountHasLegacyWorkflows = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.account_has_legacy_workflows,
  );

const makeSelectAccountWhatsappEnabled = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.whats_app_enabled);

const makeSelectAccountCanCreateStandardWorkflowTemplatesEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.can_create_standard_workflow_templates,
  );

const makeSelectTimeBasedDuplicateHandlingEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.time_based_duplicate_handling,
  );

const makeSelectFeatureAutoGenerateDummyEmailEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.auto_generate_dummy_email_enabled,
  );

/* Fountain AI feature flags start */

const makeSelectIsAccountFountainAiEnabled = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.fountain_ai_enabled);

const makeSelectFountainAiFaqEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.fountain_ai_faq_enabled,
  );

const makeSelectFeatureChatbotAdminEnabled = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.chatbot_admin_enabled);

const makeSelectFeatureChatbotAutomatedResponseEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.chatbot_automated_response_enabled,
  );

const makeSelectIsAccountFaqBotEnabled = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.is_faq_bot_enabled);

const makeSelectFeatureChatbotReviewEnabled = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.chatbot_review_enabled);

const makeSelectFountainAiCarreerSiteSettingEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.fountain_ai_carreer_site_setting_enabled,
  );

const makeSelectFountainAiCareerSiteEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.fountain_ai_career_site_enabled,
  );

/* Fountain AI feature flags end */

const makeSelectIsPostInterviewRecruiterNotificationsEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.post_interview_recruiter_notifications_enabled,
  );

const makeSelectPerms = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.perms);

const makeSelectRole = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.role);

const makeSelectIsAdmin = () =>
  createSelector([makeSelectRole()], role => role === ADMIN_ROLE);

const makeSelectHasBillingAccess = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.is_owner || whoami.perms.includes('billing'),
  );

const makeSelectAccountMenuItems = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.menu);

const makeSelectAccountSlug = () =>
  createSelector([makeSelectBrands()], brands => {
    const accountBrand = brands.find(brand => brand.isAccount);
    return accountBrand ? accountBrand.slug : null;
  });

const makeSelectAccount = () =>
  createSelector([makeSelectBrands()], brands => {
    const accountBrand = brands.find(brand => brand.isAccount);
    return accountBrand ?? null;
  });

const makeSelectCurrentBrand = () =>
  createSelector(selectBrands, brands =>
    brands.find(brand => brand.isSelected),
  );

const makeSelectDefaultBrandId = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.default_brand_id);

const makeSelectIsSuperAdmin = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.is_super_admin);

const makeSelectAccounts = () =>
  createSelector(selectAuthDomain, authState => authState.superAdmin.accounts);

const makeSelectSelectedUser = () =>
  createSelector(selectAuthDomain, authState =>
    authState.superAdmin.users.find(user => user.isSelected),
  );

const makeSelectUsers = () =>
  createSelector(selectAuthDomain, authState => authState.superAdmin.users);

const makeSelectIsImpersonating = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.is_impersonated);

const makeSelectSuperAdminUserEmail = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.true_user_email) || '';

const makeSelectSignedOut = () =>
  createSelector(selectAuthDomain, authState => authState.signedOut);

const makeSelectIsFetchingWhoami = () =>
  createSelector(selectAuthDomain, authState => authState.isLoading);

const makeSelectFirstJobId = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.first_job_id);

const makeSelectEnforceBrandBasedPermissions = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.enforce_brand_based_permissions,
  );

const makeSelectCountryCode = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.country_code);

const makeSelectPolicies = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.policies);

const makeSelectIsRegularUser = () =>
  createSelector([makeSelectWhoami()], whoami => {
    if (
      whoami.role === ADMIN_ROLE ||
      whoami.is_super_admin ||
      whoami.is_owner
    ) {
      return false;
    }
    return true;
  });

const makeSelectClientSettingsUpdateSuccess = () =>
  createSelector(
    selectAuthDomain,
    authState => authState.clientSettingsUpdateSuccess,
  );

const makeSelectEnvVars = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.recruiter_ui_env_vars);

const makeSelectUavUserBadgeDisabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['disable-uav-user-badge'],
  );

const makeSelectLastFunnelSlug = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.current_funnel_slug);

const makeSelectLastFunnelId = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.current_funnel_id);

const makeSelectLookerCustomReportsEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.looker_custom_reports_enabled,
  );

const makeSelectGetMoreTextToApplyEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.get_more_text_to_apply_enabled,
  );

const makeSelectHiringGoalsEnabled = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.hiring_goals_enabled);

const makeSelectHiringGoalsV3Enabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.hiring_goals_v3_enabled,
  );

const makeHasSubAccounts = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.has_sub_accounts);

const makeSelectDesktopNotificationEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.desktop_notification_enabled,
  );

const makeAvailableSlotsNewAvailabilityMessage = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['available-slots-new-availability-message'],
  );

const makeSelectWorkflowSystemEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['workflow-system'],
  );

const makeSelectIsEmbeddedDocusignEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['embedded-docusign'],
  );

const makeSelectCustomerAttributesEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['manage-customer-attributes'],
  );

const makeSelectImmediateHiringDecision = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['immediate-hiring-decision'],
  );

const makeSelectHasRestrictedFunnelAccess = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.has_restricted_funnel_access,
  );

const makeSelectAccountId = () =>
  createSelector([makeSelectWhoami()], whoami => whoami.account_id);

const makeIsHiringGoalsV2Enabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['hiring-goals-v2'],
  );

const makeSelectIsOpeningStatusIconV2Enabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['opening-status-icon-2-0'],
  );

const makeIsCannotRepeatStandardKeyEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['cannot-repeat-standard-key'] ?? false,
  );

const makeIsUpsFunctionalityEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['ups-functionality'] ?? false,
  );

const makeIsVonqEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['vonq-integration'],
  );

const makeIsVonqJobPostEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['vonq-job-post'],
  );

const makeSelectSlotAvailabilityBasedOpeningsEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.slot_availability_based_openings_enabled,
  );

const makeSelectOpeningApprovalEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.opening_approval_enabled,
  );

const makeSelectQrCodeForOpeningEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.qr_code_for_opening_enabled,
  );

const makeSelectOpeningCsvUpdate = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['opening-csv-update'],
  );

const makeSelectWorkflowEditorPartnerStagePreview = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['workflow-editor-partner-stage-preview'],
  );

const makeSelectCustomerAttributesRulesEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['customer-attributes-rules'],
  );

const makeSelectCanSendOnlyTemplatesInSms = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.can_send_only_templates_in_sms,
  );

const makeIsSmsLengthLimitEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['sms-length-limited'],
  );

const makeShowColumnsDrawerInSessions = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['show-columns-drawer-in-sessions'],
  );

const makeIsDelaySendingWebhookEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['hold-data-collection-partner-webhooks'],
  );

const makeIsCandidateAiEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['new-fountain-ai-chatbot'],
  );

const makeIsAimbridgeHiringGoalImprovementsEnabled = () =>
  createSelector(
    [makeSelectWhoami()],
    whoami => whoami.feature_flags['aimbridge-hiring-goals-improvements'],
  );

export {
  makeAvailableSlotsNewAvailabilityMessage,
  makeHasSubAccounts,
  makeIsAimbridgeHiringGoalImprovementsEnabled,
  makeIsAllBrandsFetched,
  makeIsCandidateAiEnabled,
  makeIsCannotRepeatStandardKeyEnabled,
  makeIsDelaySendingWebhookEnabled,
  makeIsFetchingAllBrands,
  makeIsHiringGoalsV2Enabled,
  makeIsSmsLengthLimitEnabled,
  makeIsUpsFunctionalityEnabled,
  makeIsVonqEnabled,
  makeIsVonqJobPostEnabled,
  makeSelectAccessibleAccountSlugs,
  makeSelectAccount,
  makeSelectAccountCanCreateStandardWorkflowTemplatesEnabled,
  makeSelectAccountHasLegacyWorkflows,
  makeSelectAccountHasWorkflows,
  makeSelectAccountId,
  makeSelectAccountMenuItems,
  makeSelectAccountName,
  makeSelectAccounts,
  makeSelectAccountSlug,
  makeSelectAccountSmsEnabled,
  makeSelectAccountWhatsappEnabled,
  makeSelectBrands,
  makeSelectCanSendOnlyTemplatesInSms,
  makeSelectClientSettings,
  makeSelectClientSettingsUpdateSuccess,
  makeSelectCountryCode,
  makeSelectCurrentBrand,
  makeSelectCurrentLocation,
  makeSelectCustomerAttributesEnabled,
  makeSelectCustomerAttributesRulesEnabled,
  makeSelectDefaultBrandId,
  makeSelectDesktopNotificationEnabled,
  makeSelectEmail,
  makeSelectEnforceBrandBasedPermissions,
  makeSelectEnvVars,
  makeSelectExternalId,
  makeSelectFeatureAutoGenerateDummyEmailEnabled,
  makeSelectFeatureChatbotAdminEnabled,
  makeSelectFeatureChatbotAutomatedResponseEnabled,
  makeSelectFeatureChatbotReviewEnabled,
  makeSelectFirstJobId,
  makeSelectFountainAiCareerSiteEnabled,
  makeSelectFountainAiCarreerSiteSettingEnabled,
  makeSelectFountainAiFaqEnabled,
  makeSelectGetMoreTextToApplyEnabled,
  makeSelectHasBillingAccess,
  makeSelectHasRestrictedFunnelAccess,
  makeSelectHiringGoalsEnabled,
  makeSelectHiringGoalsV3Enabled,
  makeSelectImmediateHiringDecision,
  makeSelectIsAccountFaqBotEnabled,
  makeSelectIsAccountFountainAiEnabled,
  makeSelectIsAdmin,
  makeSelectIsEmbeddedDocusignEnabled,
  makeSelectIsFetchingWhoami,
  makeSelectIsImpersonating,
  makeSelectIsLoggedIn,
  makeSelectIsOpeningStatusIconV2Enabled,
  makeSelectIsPostInterviewRecruiterNotificationsEnabled,
  makeSelectIsRegularUser,
  makeSelectIsSuperAdmin,
  makeSelectIsUpdatingClientSettings,
  makeSelectLastFunnelId,
  makeSelectLastFunnelSlug,
  makeSelectLocations,
  makeSelectLookerCustomReportsEnabled,
  makeSelectOpeningApprovalEnabled,
  makeSelectOpeningCsvUpdate,
  makeSelectPerms,
  makeSelectPhoneNumber,
  makeSelectPolicies,
  makeSelectQrCodeForOpeningEnabled,
  makeSelectRole,
  makeSelectSelectedAccountSlug,
  makeSelectSelectedUser,
  makeSelectSignedOut,
  makeSelectSlotAvailabilityBasedOpeningsEnabled,
  makeSelectSuperAdminUserEmail,
  makeSelectTelephonyEnabled,
  makeSelectTimeBasedDuplicateHandlingEnabled,
  makeSelectUavUserBadgeDisabled,
  makeSelectUnreadSms,
  makeSelectUserName,
  makeSelectUsers,
  makeSelectWhoami,
  makeSelectWhoamiError,
  makeSelectWorkflowEditorPartnerStagePreview,
  makeSelectWorkflowSystemEnabled,
  makeShowColumnsDrawerInSessions,
};
